<template lang="pug">

	.services

		section.services__section.services__section--main

			img.services__img.services__img--main(src="~@/assets/img/services/services_main_section_back.jpg")

			.services__container.services__container--main(class="container u-h100")

				.services__content

					h1.services__title(class="secondary-title") We can provide turn key solutions for your project.

					p.services__sub-title(class="sub-title") Titus Surfacing is a turnkey manufacturer and installer who can perform all aspects of the surfacing for your project. We will work with you at the inception of the project to help you develop a design for your project.

					button.services__button.services__button--main(class="btn btn--default" @click="$router.push('/contact')") Contact


		section.services__section.services__section--list

			img.services__img.services__img--list(src="~@/assets/img/services/ic_list_back.svg")


			.services__container.services__container--list(class="container")

				h2.services__list-title(class="tertiary-title") We are pleased to offer the following services:

				.services__list(class="u-df-row-wrap-start" v-if="items.length")

					.services__list-item-wrapper(class="u-df-center" v-for="(item, index) in items" :key="index")

						.services__list-item(class="u-df-column-center-center")
							img.services__list-image(:src="item.img")
							.services__list-description {{ item.description }}


		section.services__section.services__section--photos

			.services__container.services__container--photos(class="container u-df-between")

				.services__photo-card(class="u-df-column-center" v-for="(item, index) in photos" :key="index")

					img.services__photo(:src="item.img")

					.services__photo-title-box
						h3.services__photo-title(class="article-title") {{ item.description }}


		section.services__section.services__section--contact(class="u-df-center")

			img.services__img.services__img--contact(src="~@/assets/img/services/contact_section_back.jpg")
		
			.services__container.services__container--contact(class="container u-df-between")
				BaseContactFormComponent(:caption="caption" :article="article")


</template>

<script>
export default {
	name: 'ServicesView',

	data() {
		return {
			items: [
				{
					img: require('@/assets/img/services/Ic_Pre-Construction.svg'),
					description: 'Pre-Construction Design and Estimating'
				},
				{
					img: require('@/assets/img/services/Ic_Subbase-installation.svg'),
					description: 'Subbase installation including concrete, asphalt, or aggregate'
				},
				{
					img: require('@/assets/img/services/Ic_Professional-Installation.svg'),
					description: 'Professional Installation of all our products'
				},
				{
					img: require('@/assets/img/services/Ic_Clean-up.svg'),
					description: 'Clean up and Landscaping'
				},
				{
					img: require('@/assets/img/services/Ic_Professional-test.svg'),
					description: 'Drop testing of our safety surfacing'
				}
			],
			photos: [
				{
					img: require('@/assets/img/services/concrete_services.jpg'),
					description: 'Concrete services'
				},
				{
					img: require('@/assets/img/services/services_rubber_installation.jpg'),
					description: 'Surfacing Installation'
				}
			],
			caption: 'If you are interested in hearing more about our services, please',
			article: ''
		}
	}
}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.services
	
	
	&__section


		&--main
			min-height: 100vh
			position: relative


			@media screen and (max-width: $tablet)
				min-height: 85rem


		&--list
			position: relative
			

		&--contact
			position: relative
			margin: 17rem 0 15rem


			@media screen and (max-width: $big_desktop)
				margin-top: 10rem


			@media screen and (max-width: $tablet)
				margin: 0


		&--photos
			margin: 27rem 0 31.3rem


			@media screen and (max-width: $big_desktop)
				margin: 25rem 0


			@media screen and (max-width: $tablet)
				margin: 8rem 0 15rem


	&__container


		&--contact


			@media screen and (max-width: $tablet)				
				display: flex
				align-items: center
				justify-content: center


		&--photos


			@media screen and (max-width: $small_desktop)				
				display: flex
				flex-direction: column
				justify-content: center
	
	
	&__img


		&--main
			object-fit: contain
			object-position: top

			position: absolute
			
			top: 0
			right: 0
			
			height: 93rem

			max-height: 85vh
			max-width: 55%


			@media screen and (max-width: $tablet)
				max-height: 31rem
				max-width: 101%


		&--list
			object-fit: contain
			object-position: left

			position: absolute
			
			top: 4rem
			left: 0
			
			height: 53rem

			max-height: 85vh
			max-width: 55%

			z-index: -1


		&--contact
			object-fit: contain
			object-position: right

			position: absolute
			
			top: -4rem
			right: 0

			height: 120%
			max-width: 50%

			z-index: -1


			@media screen and (max-width: $tablet)
				top: -10rem
				max-width: 99%


	&__content
		position: absolute

		top: 33vh


		@media screen and (max-width: $tablet)
			width: 89%
			top: 32rem

			text-align: center


	&__title
		width: 69.2rem
		margin-bottom: 4rem


		@media screen and (max-width: $tablet)
			width: 100%


	&__sub-title
		width: 67.8rem
		margin-bottom: 8rem


		@media screen and (max-width: $tablet)
			width: 100%
			margin-bottom: 5rem


	&__button


		&--main
			display: block

			width: 26.3rem

			
			@media screen and (max-width: $tablet)
				display: inline-block


	&__list


		@media screen and (max-width: $big_desktop)
			position: relative

			right: -5.3rem


		@media screen and (max-width: $tablet)
			right: 0
			justify-content: center


		&-title
			margin-bottom: 10.7rem


		&-item
			height: 31.8rem
			width: 24.7rem

			background: $color_white

			box-shadow: 0px 5px 10px rgba($color_black, .15)

			border-radius: 3rem

			padding: 0 2.5rem

			cursor: default


			&-wrapper
				flex-basis: 20%
				min-width: 24.7rem

				margin: 0 0 7rem 0


				@media screen and (max-width: $big_desktop)


					&:not(:last-child)
						margin-right: 5.3rem


				@media screen and (max-width: $iphone_plus)
					margin-right: 0 !important


		&-image
			width: 15rem
			height: 15rem

			margin-bottom: 2.1rem


		&-description
			text-align: center


	&__photo-card
		width: 54.6rem
		height: 47.9rem

		border-radius: 5rem

		overflow: hidden

		box-shadow: 0px 5px 10px rgba($color_black, .15)


		@media screen and (max-width: $small_desktop)
			width: 100%
			height: unset


		&:not(:last-child)


			@media screen and (max-width: $small_desktop)
				margin-bottom: 10rem


	.services__photo
		width: 100%

		flex-basis: 77%

		object-fit: cover


		@media screen and (max-width: $tablet)
			flex-basis: unset


	&__photo-title-box
		width: 100%
		
		flex-basis: 23%

		padding: 4.6rem


		@media screen and (max-width: $tablet)
			flex-basis: unset


</style>